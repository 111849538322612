import React, { useEffect } from "react";
import { isAfter } from "date-fns";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";
import Image from "components/common/Image";

import Link from "components/common/Link";
import BlogCarousel from "components/cards/BlogCarousel";

import { LinkModal } from "kh-common-components";

import { mermaidFlowmojiBgUrl } from "constants/urls";
import { modalContents } from "constants/modal-content";

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import imgTccLogo from "images/telethon-community-cinemas/logo-telethon-community-cinemas.png";
import imgBannerPopcorn from "images/telethon-community-cinemas/hero-banner-popcorn-tickets.png";
import imgBanner from "images/telethon-community-cinemas/hero-banner-popcorn.png";
import imgParklands from "images/telethon-community-cinemas/parklands.png";

import "styles/pages/telethon-community-cinemas.css";

function TelethonCommunityCinemasPage() {
  useEffect(() => {
    const currentDate = new Date();
    const dateStrOfferScreeningExpiredStr = process.env.TCC_SCREENING_EXPIRE.split(
      "-"
    );
    const dateStrOfferScreeningStartStr = process.env.TCC_SCREENING_START.split(
      "-"
    );

    const dateStrOfferScreeningExpired = new Date(
      dateStrOfferScreeningExpiredStr[0],
      dateStrOfferScreeningExpiredStr[1] - 1,
      dateStrOfferScreeningExpiredStr[2],
      dateStrOfferScreeningExpiredStr[3]
        ? dateStrOfferScreeningExpiredStr[3]
        : "",
      dateStrOfferScreeningExpiredStr[4]
        ? dateStrOfferScreeningExpiredStr[4]
        : ""
    );

    const dateStrOfferScreeningStart = new Date(
      dateStrOfferScreeningStartStr[0],
      dateStrOfferScreeningStartStr[1] - 1,
      dateStrOfferScreeningStartStr[2],
      dateStrOfferScreeningStartStr[3] ? dateStrOfferScreeningStartStr[3] : "",
      dateStrOfferScreeningStartStr[4] ? dateStrOfferScreeningStartStr[4] : ""
    );

    const giveawaysScreeningCont = document.querySelectorAll(
      `section[data-gatsby-id='giveaways-screening']`
    );
    const giveawaysScreeningEndCont = document.querySelectorAll(
      `section[data-gatsby-id='giveaways-screening-end']`
    );

    const isOfferScreeningStart = isAfter(
      currentDate,
      dateStrOfferScreeningStart
    );
    const isOfferScreeningExpired = isAfter(
      currentDate,
      dateStrOfferScreeningExpired
    );

    if (isOfferScreeningStart && !isOfferScreeningExpired) {
      giveawaysScreeningCont.forEach(divs => {
        divs.classList.remove("d-none");
      });
      giveawaysScreeningEndCont.forEach(divs => {
        divs.classList.add("d-none");
      });
    }
  }, []);

  return (
    <>
      <SEO
        title="Telethon Community Cinemas | Kleenheat | Community Support"
        titleBespoke="true"
        description="Kleenheat is a proud partner of Telethon Community Cinemas, which supports local charities making life easier for kids in health and physical crisis."
      />
      <Header />
      <LayoutMain>
        {/* hero banner */}
        <LayoutPanel
          background="mer-bg--primary-cyan"
          padding="mer-pb-ju"
          theme="mer-theme--cyan"
          id="tcc-hero-banner"
        >
          <div className="container">
            <div className="row mer-panel justify-content-center align-items-end">
              <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">
                <div className="logo-tcc">
                  <img src={imgTccLogo} alt="Telethon Community Cinemas" />
                </div>

                <section
                  className="d-none"
                  data-gatsby-id="giveaways-screening"
                >
                  <div className="content">
                    <h2>Your favourite community cinemas are back!</h2>

                    <p>
                      Kleenheat’s sponsorship continues for the latest season of
                      the{" "}
                      <a
                        href="https://communitycinemas.com.au/"
                        title="Open Telethon Community Cinemas in a new tab"
                        target="_blank"
                      >
                        Telethon Community Cinemas
                      </a>
                      , supporting their commitment to WA children’s charities.
                      The season runs from Thursday 14 November 2024, to
                      Saturday 26 April 2025, at four fantastic locations across
                      Perth.
                    </p>

                    <p>
                      <strong>Kleenheat customers</strong> can access{" "}
                      <strong>2-for-1 adult tickets*</strong> valid until
                      Saturday 26 April 2025.
                    </p>

                    <div className="mer-panel__actions mer-button-group">
                      <Link
                        text="Ticket offer"
                        link="#tickets"
                        linkClass="mer-button mer-button--primary"
                      />

                      <Link
                        text="Learn more"
                        link="/blog/community/telethon-community-cinemas-guide"
                        linkClass="mer-button mer-button--secondary"
                      />
                    </div>

                    <p>
                      <small>
                        <LinkModal
                          modalContents={modalContents}
                          useModal="true"
                          title="Terms & Conditions"
                          // linkClass=""
                          displayContent="*Terms and conditions apply"
                          modalTitle="Terms & Conditions"
                          modalContentName="terms-tcc-two-tickets"
                          okayButtonLabel="Close"
                        />
                      </small>
                    </p>
                  </div>
                </section>

                <section data-gatsby-id="giveaways-screening-end">
                  <div className="content">
                    <h2>Community cinemas are back!</h2>

                    <p>
                      We’re proud to be once again, sponsoring the{" "}
                      <a
                        href="https://communitycinemas.com.au/"
                        title="Open Telethon Community Cinemas in a new tab"
                        target="_blank"
                      >
                        Telethon Community Cinemas
                      </a>{" "}
                      and its commitment to giving back to Children’s charities
                      in WA. The season runs from Thursday 14 November 2024, to
                      Saturday 26 April 2025, at four fantastic locations across
                      Perth.
                    </p>

                    <p>
                      <strong>Kleenheat customers</strong> have the chance to
                      win a bunch of <strong>complimentary tickets!</strong>
                    </p>

                    <div className="mer-panel__actions mer-button-group">
                      <Link
                        text="Learn more"
                        link="/blog/community/telethon-community-cinemas-guide"
                        linkClass="mer-button mer-button--primary"
                      />
                    </div>
                  </div>
                </section>
              </div>

              <section className="mer-panel__images col-8 col-sm-5 col-lg-4 d-none" data-gatsby-id="giveaways-screening">
                <img
                  src={imgBannerPopcorn}
                  className="mer-panel__image"
                  alt="Kleenheat Popcorn"
                />
              </section>

			  <section className="mer-panel__images col-8 col-sm-5 col-lg-4" data-gatsby-id="giveaways-screening-end">
                <img
                  src={imgBanner}
                  className="mer-panel__image"
                  alt="Kleenheat"
                />
              </section>
            </div>
          </div>
        </LayoutPanel>

        <section
          className="module-wrapper mer-theme--light mer-bg--ui-light mer-py-lg mer-border--bottom d-none"
          id="tickets"
          style={{
            backgroundImage: `url(${mermaidFlowmojiBgUrl})`,
            backgroundRepeat: "repeat",
            backgroundSize: "60vh",
          }}
          data-gatsby-id="giveaways-screening"
        >
          <div className="container">
            <div className="row mer-panel align-items-center justify-content-center">
              <div className="col-10 col-sm-7 col-lg-6 order-sm-2 offset-sm-1">
                <div className="content mer-text--size-lg">
                  <h2 className="mer-typography--headline3">
                    2-for-1 tickets for{" "}
                    <span className="mer-text--no-wrap">
                      Kleenheat customers
                    </span>
                  </h2>

                  <p>
                    Use the online discount code <strong>KLEEN25</strong> to
                    purchase one adult ticket and get another free*. Valid for
                    all screenings from Saturday 1 February 2025, to Saturday 26
                    April 2025, so{" "}
                    <a
                      href="https://communitycinemas.com.au/"
                      title="Purchase tickets in a new tab"
                      target="_blank"
                      className="mer-text--no-wrap"
                    >
                      get your tickets now.
                    </a>
                  </p>

                  <p>Enjoy your movie!</p>
                  <div>
                    <small>
                      <LinkModal
                        modalContents={modalContents}
                        useModal="true"
                        title="Terms & Conditions"
                        // linkClass=""
                        displayContent="*Terms and conditions apply"
                        modalTitle="Terms & Conditions"
                        modalContentName="terms-tcc-two-tickets"
                        okayButtonLabel="Close"
                      />
                    </small>
                  </div>
                </div>
              </div>

              <div className="mer-panel__images col-5 col-sm-3 col-lg-2 order-sm-1">
                <Image
                  className="mer-panel__image"
                  src="/icons/flowmoji/flowmoji-tickets.svg"
                  alt="Discount Illustration"
                />
              </div>
            </div>
          </div>
        </section>

        {/* support */}
        <LayoutPanel
          background="mer-bg--gs-grey-lighter"
          padding="mer-py-lg"
          border="mer-border--bottom"
          id="support"
        >
          <div className="container">
            <div className="row mer-panel align-items-center justify-content-center">
              <div className="mer-panel__images col-10 col-sm-6 order-sm-1">
                <img
                  src={imgParklands}
                  className="mer-panel__image"
                  alt="Parklands"
                />
              </div>

              <div className="content col-10 col-md-5 col-sm-8 order-sm-2 offset-md-1">
                <div className="content">
                  <h2 className="mer-typography--headline4">
                    <a
                      href="/blog/community/telethon-community-cinemas-guide"
                      title="Check out the Telethon Community Cinemas guide"
                    >
                      Your guide to the Telethon Community Cinemas.
                    </a>
                  </h2>
                  <p>
                    Plan your perfect movie night with our handy audience guide.
                    We give you the details on snacks, seating, cinema
                    locations, and more.
                  </p>

                  <div className="mer-panel__actions mer-button-group">
                    <Link
                      text="Check out the guide"
                      link="/blog/community/telethon-community-cinemas-guide"
                      linkClass="mer-button mer-button--primary"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mer-panel align-items-center justify-content-center mt-3">
              <div className="mer-panel__videos col-10 col-sm-6 order-sm-2 offset-md-1">
                <div className="mer-panel__video">
                  <iframe
                    src="https://player.vimeo.com/video/684051573?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
                    frameBorder="0"
                    webkitallowfullscreen=""
                    mozallowfullscreen=""
                    allowFullScreen=""
                    data-vimeo-tracked="true"
                    data-ready="true"
                  ></iframe>
                </div>
              </div>

              <div className="content col-10 col-md-5 col-sm-8 order-sm-1">
                <div className="content">
                  <h2 className="mer-typography--headline4">
                    <a
                      href="/blog/community/telethon-community-cinemas"
                      title="Click to view the founding of Perth's most affordable outdoor cinema"
                    >
                      The founding of Perth’s most affordable outdoor cinema
                      experience.
                    </a>
                  </h2>
                  <p>
                    We met with Ken Gibbons to learn about the beginnings of
                    Telethon Community Cinemas and how it gives back to the WA
                    community.
                  </p>

                  <div className="mer-panel__actions mer-button-group">
                    <Link
                      text="Learn more"
                      link="/blog/community/telethon-community-cinemas"
                      linkTarget="_blank"
                      linkClass="mer-button mer-button--primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutPanel>

        {/* usp 
				<LayoutPanel background="mer-bg--ui-light"
							padding="mer-pt-ju"
							id="giveaways">
					<div className="container">

						<div className="row align-items-center justify-content-center">
							<div className="col-10 col-sm-8 mer-text--size-lg">
								<div className="content text-center">
									<h1 className="mer-color--primary-cyan mer-typography--headline3">Lights, camera, act - on these great offers.</h1>
								</div>
							</div>
						</div>

						<section data-gatsby-id="giveaways-screening" className="d-none">
							<Panel data={panelData} 
									id="screening"
									imgLocal={imgWrappedPresent}
							/>
						</section>

						<section data-gatsby-id="giveaways-screening-end">
							<Panel data={panelData} 
									id="screening-end"
									imgLocal={imgWrappedPresent}
							/>
						</section>
					</div>
				</LayoutPanel>
				*/}

        <BlogCarousel
          id="stories"
          title="Learn more about Telethon Community Cinemas"
          titleCol="col-10"
          // apiQuery="?tag=moving-house&posts_per_page=6"
          apiQuery="?tag=community-cinemas"
        />
      </LayoutMain>
      <Footer />
    </>
  );
}

export default TelethonCommunityCinemasPage;
